import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/text_mob.png'
import _imports_1 from '@/assets/images/phone_mob.png'
import _imports_2 from '@/assets/images/text_web.png'
import _imports_3 from '@/assets/images/phone_web.png'


const _hoisted_1 = {
  key: 0,
  class: "push-confirm-reminder"
}
const _hoisted_2 = {
  key: 0,
  class: "fullscreen"
}
const _hoisted_3 = {
  key: 1,
  class: "push-animation-desktop-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.isActive)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (($setup.isMobile && $setup.isPortrait) || $setup.isNarrowScreen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "fullscreen animation-mobile-background" }, null, -1),
              _createElementVNode("img", {
                class: "absolute-center",
                id: "push-animation-text-mobile",
                src: _imports_0
              }, null, -1),
              _createElementVNode("img", {
                src: _imports_1,
                id: "push-animation-image-mobile"
              }, null, -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [
              _createElementVNode("img", {
                id: "push-animation-text-desktop",
                src: _imports_2
              }, null, -1),
              _createElementVNode("img", {
                id: "push-animation-image-desktop",
                src: _imports_3
              }, null, -1)
            ])))
      ]))
    : _createCommentVNode("", true)
}