import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/emoji/emoji_loading_screen.png'


const _hoisted_1 = { class: "payment-dialog-small q-pa-xl" }
const _hoisted_2 = {
  key: 0,
  id: "loading-screen",
  class: "q-ma-md payment-dialog-waiting row items-center"
}
const _hoisted_3 = {
  class: "col q-pa-md",
  style: {"height":"120px","width":"120px","margin-top":"-120px"}
}
const _hoisted_4 = {
  key: 1,
  class: "q-pa-md"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lottie_animation = _resolveComponent("lottie-animation")!
  const _component_push_confirm_reminder = _resolveComponent("push-confirm-reminder")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_lottie_animation, {
              id: "loading-animation",
              path: "wait-spinner.json"
            }),
            _cache[0] || (_cache[0] = _createElementVNode("div", {
              class: "main-user-message q-mt-md",
              id: "user-message"
            }, [
              _createTextVNode(" Грузить, сподіваємось не тебе "),
              _createElementVNode("img", {
                src: _imports_0,
                style: {"height":"20px"}
              })
            ], -1)),
            _cache[1] || (_cache[1] = _createElementVNode("div", { id: "loading-screen-user-description" }, " Дочекайся повного завантаження ", -1))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])),
    ($setup.showAnimation)
      ? (_openBlock(), _createBlock(_component_push_confirm_reminder, {
          key: 2,
          ref: "pushConfirmReminder",
          isActive: $setup.isPushConfirmNotificationVisible
        }, null, 8, ["isActive"]))
      : _createCommentVNode("", true)
  ]))
}