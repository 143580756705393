import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/wtf.svg'


const _hoisted_1 = { class: "column items-center payment-authorization-dialog" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_payment_available_attempts_view = _resolveComponent("payment-available-attempts-view")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_back_to_store_button = _resolveComponent("back-to-store-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"payment-dialog-small-body\" data-v-420fdf23><div class=\"col\" data-v-420fdf23><img src=\"" + _imports_0 + "\" class=\"small-dialog-image\" data-v-420fdf23></div><div class=\"col main-user-message\" data-v-420fdf23>Ти сказав &quot;ні&quot; платежу</div><div class=\"col user-data-small q-pa-md\" data-v-420fdf23><span data-v-420fdf23>Тa скасував оплату в мобільному додатку</span></div></div>", 1)),
    _createVNode(_component_payment_available_attempts_view, {
      ref: "payment-available-attempts-view",
      attemptsAvailable: $setup.availableConfirmationAttemptCount
    }, null, 8, ["attemptsAvailable"]),
    ($setup.availableConfirmationAttemptCount)
      ? (_openBlock(), _createBlock(_component_q_btn, {
          key: 0,
          ref: "send-again-button",
          "no-caps": "",
          class: "dialog-button",
          label: "Надіслати повторно",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.handlePaymentConfirmation()))
        }, null, 512))
      : _createCommentVNode("", true),
    (!$setup.availableConfirmationAttemptCount)
      ? (_openBlock(), _createBlock(_component_back_to_store_button, {
          key: 1,
          url: $setup.failureRedirectUrl
        }, null, 8, ["url"]))
      : _createCommentVNode("", true)
  ]))
}