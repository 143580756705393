import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/error.png'
import _imports_1 from '@/assets/emoji/emoji_payment_fail.png'


const _hoisted_1 = { class: "column items-center payment-authorization-dialog" }
const _hoisted_2 = { class: "payment-dialog-small-body height-auto" }
const _hoisted_3 = {
  class: "col user-data-small attention q-pt-md",
  id: "error-description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col" }, [
        _createElementVNode("img", {
          src: _imports_0,
          class: "small-dialog-image",
          id: "payment-error-image"
        })
      ], -1)),
      _cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "col main-user-message",
        id: "error-message-container"
      }, [
        _createTextVNode(" Халепка "),
        _createElementVNode("img", {
          id: "payment-error-emoji",
          src: _imports_1,
          style: {"height":"25px","margin-bottom":"-5px"}
        }),
        _createElementVNode("br"),
        _createTextVNode("Платіж не пройшов ")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createTextVNode(" І ось чому: ")),
        _withDirectives(_createElementVNode("span", null, null, 512), [
          [_directive_t, { path: `error.${$setup.errorCode}` }]
        ])
      ])
    ]),
    _createVNode(_component_q_btn, {
      ref: "backToStoreButton",
      "no-caps": "",
      class: "dialog-button",
      label: "До магазину",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.handleBackToStoreClick()))
    }, null, 512)
  ]))
}