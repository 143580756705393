import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/bonus.svg'


const _hoisted_1 = {
  key: 0,
  id: "loyalty-points-panel"
}
const _hoisted_2 = {
  key: 0,
  class: "row items-center",
  id: "bonus-amount-container"
}
const _hoisted_3 = { class: "q-ml-xs" }
const _hoisted_4 = {
  id: "bonus-amount",
  class: "user-data-small-bold col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_spinner_tail = _resolveComponent("q-spinner-tail")!

  return (!_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isBonusAmountVisible || _ctx.isVerify)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_q_separator, {
                vertical: "",
                inset: "",
                spaced: "",
                ref: "separator"
              }, null, 512),
              _createElementVNode("div", null, [
                _createVNode(_component_q_img, {
                  class: "col",
                  src: _imports_0,
                  id: "bonus-icon"
                })
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.bonusAsString), 1),
                _cache[0] || (_cache[0] = _createElementVNode("div", {
                  id: "bonus-amount-description",
                  class: "user-data-small"
                }, " Від Банку ", -1))
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createBlock(_component_q_spinner_tail, {
        key: 1,
        color: "grey-5",
        size: "3em",
        class: "q-ml-md q-mt-xs",
        ref: "spinner"
      }, null, 512))
}