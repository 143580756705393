import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "payment-info",
  class: "q-mt-md"
}
const _hoisted_2 = { class: "q-mr-md" }
const _hoisted_3 = { id: "sum-amount" }
const _hoisted_4 = { id: "integer-sum-part" }
const _hoisted_5 = {
  id: "decimal-sum-part",
  class: "decimal-sum"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loyalty_points_panel = _resolveComponent("loyalty-points-panel")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("article", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "user-data-small",
        id: "sum-caption"
      }, "До сплати", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString($setup.integerSumPart), 1),
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_5, "." + _toDisplayString($setup.decimalSumPart), 1)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", {
          id: "currency-symbol",
          class: "decimal-sum"
        }, " ₴", -1))
      ])
    ]),
    _createVNode(_component_loyalty_points_panel, {
      class: "q-pt-xs",
      "is-loading": $props.isLoyaltyPointsLoading,
      "auth-type": $props.authType
    }, null, 8, ["is-loading", "auth-type"])
  ]))
}