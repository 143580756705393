import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/temp-images/additional_bonus_desktop.svg'
import _imports_1 from '@/assets/images/temp-images/additional_bonus_mobile.svg'


const _hoisted_1 = {
  key: 0,
  id: "additional-bonus-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isTargetMerchant && !_ctx.isAuthTypeVerify)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _imports_0,
          id: "additional-bonus-desktop",
          class: _normalizeClass({'bunner-verify-mode': _ctx.isAuthTypeVerify})
        }, null, 2),
        _createElementVNode("img", {
          src: _imports_1,
          id: "additional-bonus-mobile",
          class: _normalizeClass({'bunner-verify-mode-mobile': _ctx.isAuthTypeVerify})
        }, null, 2)
      ]))
    : _createCommentVNode("", true)
}