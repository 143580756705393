import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/images/edit.svg'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  style: {"height":"23px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["user-data-medium q-mt-md", { error: $props.isError }]),
      id: "label"
    }, " Номер телефону ", 2),
    _createVNode(_component_q_input, {
      ref: "phoneNumberInput",
      style: {"margin-top":"-10px"},
      color: "blue-5",
      modelValue: $setup.phone,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => (($setup.phone) = $event)),
        _cache[1] || (_cache[1] = (value) => $setup.handleNumberInput(value))
      ],
      mask: "(###) ###-##-##",
      errorMessage: $props.errorMessage,
      disable: $props.disable,
      error: $props.isError,
      "stack-label": "",
      class: "user-data-medium"
    }, {
      prepend: _withCtx(() => [
        _cache[2] || (_cache[2] = _createElementVNode("span", {
          class: "user-data-medium",
          id: "phone-code"
        }, "+38", -1)),
        _createVNode(_component_q_separator, {
          vertical: "",
          class: "vertical-separator"
        })
      ]),
      append: _withCtx(() => [
        (!$props.disable)
          ? (_openBlock(), _createElementBlock("img", _hoisted_1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "errorMessage", "disable", "error"])
  ]))
}